import { ErrorMessage, Field, FieldProps } from 'formik';
import { DatePicker, Input, Select, Switch, Tooltip, Upload } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectValue } from 'antd/es/select';
import Papa from 'papaparse';
import { localStorageHelper } from '../../../utils';
import { QuestionCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { getGameConfigurationsByUser } from '../../../redux/selectors/user-selectors';
import { IGameItemData } from '../../../helpers/interfaces';

interface CommonProps extends FieldProps {
    form: any;
    field: any;
    onChange?: (checked: boolean) => void;
}

interface FormikSelectProps extends CommonProps {
    options: any[];
    children?: React.ReactNode;
}

interface FormikUploadProps extends CommonProps {
    children: React.ReactNode;
}

interface FormikSwitchProps extends CommonProps {
    id: string;
    name: string;
    checked?: boolean;
}

interface FormikFieldProps {
    name: string;
    category: string;
    type?: string;
    mode?: string;
    maxTagCount?: number | 'responsive';
    maxTagTextLength?: number;
    defaultValue?: string | any;
    children?: React.ReactNode;
    options?: any[];
    onChange?: (checked: boolean) => void;
}

export const FormikSwitch = (props: FormikSwitchProps) => {
    const { field, checked } = props;
    const handleChange = (checked: boolean) => {
        field.onChange({ target: { value: checked, name: field.name } });
        if (props.onChange) {
            props.onChange(checked);
        }
    };
    return <Switch {...field} checked={checked} onChange={handleChange} />;
};

export const FormikInput = ({ field, ...props }: CommonProps) => {
    const { t } = useTranslation();

    const addonProps: any = {};
    if (field.name === 'expires_in_days') {
        addonProps.addonAfter = t('days');
    }

    return <Input {...field} {...props} {...addonProps} />;
};

export const FormikSelect = ({ field, form, ...props }: FormikSelectProps) => {
    const shouldShowSearch = ['countries', 'currency', 'game_name', 'timezone'].includes(
        field.name,
    );
    const countriesList =
        field.name === 'countries' ? localStorageHelper.getChainedValue('user.countries') : null;
    const gamesList: any[] = useSelector(getGameConfigurationsByUser).filter(
        (game: IGameItemData) => game.freeRoundsEnabled,
    );
    const handleChange = (value: SelectValue) => {
        let valArray = Array.isArray(value) ? (value as string[]) : [value as string];

        const isEmptyOptions =
            (field.name === 'countries' && (!countriesList || countriesList.length === 0)) ||
            (field.name === 'game_name' && (!gamesList || gamesList.length === 0));

        if (!isEmptyOptions && (field.name === 'countries' || field.name === 'game_name')) {
            if (valArray.includes('all')) {
                const allIndex = valArray.indexOf('all');
                if (allIndex > 0) {
                    valArray = ['all'];
                } else if (allIndex === 0 && valArray.length > 1) {
                    valArray = valArray.filter((v) => v !== 'all');
                } else if (
                    (countriesList && valArray.length === countriesList.length) ||
                    (gamesList && valArray.length === gamesList.length)
                ) {
                    valArray = ['all'];
                }
            }
        }

        form.setFieldValue(field.name, Array.isArray(value) ? valArray : valArray[0]);
    };

    return (
        <Select
            {...field}
            {...props}
            maxTagCount="responsive"
            showSearch={shouldShowSearch}
            value={field.value}
            onChange={handleChange}
        >
            {props.children}
        </Select>
    );
};

export const FormikDatePicker = ({ field, form, ...props }: CommonProps) => {
    const handleChange = (value: any, dateString: string | string[]) => {
        const effectiveDateString = Array.isArray(dateString) ? dateString[0] : dateString;
        const isoDateString = effectiveDateString ? effectiveDateString.replace(' ', 'T') : null;
        form.setFieldValue(field.name, isoDateString);
    };

    return (
        <DatePicker
            {...props}
            defaultValue={field.value ? dayjs(field.value) : undefined}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onChange={handleChange}
            showNow={false}
        />
    );
};

export const FormikUpload = ({ form, field, ...props }: FormikUploadProps) => {
    const onChange = (info: any) => {
        if (info.file.status !== 'uploading') {
            if (info.file.status === 'removed') {
                form.setFieldValue('file', null);
                form.setFieldValue('players', '');
                return;
            }

            const csvReader = new FileReader();

            form.setFieldValue('file', info.file);

            csvReader.onloadend = () => {
                if (csvReader.result) {
                    const parsedData = Papa.parse(csvReader.result.toString());
                    form.setFieldValue('players', parsedData.data.length);
                }
            };

            csvReader.readAsText(info.file);
        }
    };

    return (
        <Upload
            {...props}
            defaultFileList={field.value ? [field.value] : []}
            accept="text/csv"
            multiple={false}
            onChange={onChange}
            beforeUpload={() => {
                return false;
            }}
        />
    );
};

export const FormikField = ({
    name,
    category,
    type,
    mode,
    children,
    options,
}: FormikFieldProps) => {
    const categoryToComponentMap: any = {
        input: FormikInput,
        select: FormikSelect,
        upload: FormikUpload,
        datePicker: FormikDatePicker,
        switch: FormikSwitch,
    };
    const { t } = useTranslation();

    return (
        <div className="new-campaign-form-item">
            <label className="new-campaign-form-item__label" htmlFor={name}>
                {name === 'expires_in_days' && (
                    <Tooltip title={t('expires_in_tootltip')}>
                        <QuestionCircleOutlined style={{ marginRight: '3px' }} />
                    </Tooltip>
                )}
                {/*{name === 'rules_url' && (*/}
                {/*    <Tooltip title={t('rules_url_tooltip')}>*/}
                {/*        <QuestionCircleOutlined style={{ marginRight: '3px' }} />*/}
                {/*    </Tooltip>*/}
                {/*)}*/}
                {t(name) + ':'}
            </label>
            <div className="new-campaign-form-item__field">
                <Field
                    id={name}
                    name={name}
                    type={type}
                    mode={mode}
                    // defaultValue={defaultValue}
                    component={categoryToComponentMap[category]}
                    options={options}
                    placeholder={t(name + '_placeholder')}
                >
                    {children}
                </Field>
                <ErrorMessage
                    className="new-campaign-form-item__error"
                    name={name}
                    component="div"
                />
            </div>
        </div>
    );
};
